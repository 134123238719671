<template>
  <div class="box processManage">
    <!-- 通知管理 -->
    <!-- <navi-gation /> -->
    <el-row class="common-wrapper" v-if="flag == 1">
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-input
            v-model.trim="search0"
            size="small"
            :placeholder="$t('ContentManage.Informmanage.Placetitlecon')"
            @keyup.enter.native="searchList"
            @input="(e) => (search0 = validForbid(e))"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-button
            size="small"
            class="btn searchbtn"
            type="primary"
            @click="searchList"
            >{{ $t("public.Inquire") }}</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-col :span="24">
          <el-button-group>
            <el-button
              type="primary"
              @click="isflag"
              style="margin-left: 0px"
              class="btn addbtn"
              size="small"
              >{{ $t("public.New") }}</el-button
            >
            <!-- <el-button
                @click="distribution1"
                :disabled="hasSeleted"
                :type="typeInfo3"
                size="small"
                >分配</el-button
              > -->
            <el-button
              @click="distribution1(delarr)"
              :type="typeInfo"
              :disabled="!hasSeleteds"
              size="small"
              >{{ $t("public.Distribution") }}</el-button
            >
            <el-button
              style="
                border-radius: 0px 3px 3px 0px;
                margin: 0px 0px 0px 0px;
                margin-left: 1.3px;
              "
              @click="delArr(delarr)"
              :type="typeInfo"
              :disabled="!hasSeleteds"
              size="small"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-table
          :row-key="getRowKeys"
          stripe
          ref="multipleTable"
          :data="dictData"
          size="mini"
          @selection-change="handleSelectionChange"
          @select="checkChange"
          @sort-change="sort"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          style="width: 100%"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          class="sort_table"
        >
          <el-table-column
            :reserve-selection="true"
            header-align="center"
            align="center"
            type="selection"
            width="60"
            :selectable="selectEnable"
          >
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="title"
            :label="$t('ContentManage.Informmanage.Title')"
            width="180"
            sortable
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="content"
            :label="$t('ContentManage.Informmanage.Content')"
            width="180"
            sortable
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="groupFullName"
            :label="$t('public.Section')"
            width="180"
            sortable
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="userName"
            :label="$t('ContentManage.Informmanage.Publisher')"
            width="180"
            sortable
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column
              prop="readAndAllocated"
              label="阅读/分配"
              width="180"
              :show-overflow-tooltip="true"
            >
              <template v-slot="{ row }">
                <template v-if="row.readAndAllocated == '0/0'">
                  <span>{{ row.readAndAllocated }}</span>
                </template>
                <template v-else>
                  <span style="color: #e6a23c; cursor: pointer" @click="nameDetail(row)">{{
                    row.readAndAllocated
                  }}</span>
                </template>
              </template>
            </el-table-column> -->
          <el-table-column
            prop="updateTime"
            :label="$t('ContentManage.Informmanage.CreationTime')"
            width="180"
            sortable
            header-align="left"
            align="left"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">{{
              row.updateTime | dateformat
            }}</template>
          </el-table-column>
          <el-table-column
            :label="$t('public.Controls')"
            header-align="left"
            align="left"
          >
            <template v-slot="{ row }">
              <span class="action_icon">
                <img
                  @click="nameDetail(row)"
                  src="@/assets/icon_see.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('ContentManage.Informmanage.Readingassignment')"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="distribution(row)"
                  src="@/images/icon_delivery.png"
                  :title="$t('public.Distribution')"
                  alt=""
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
              <span class="action_icon">
                <img
                  v-if="row.appStatus !== 2"
                  @click="del(row.id)"
                  src="@/images/icon_del.png"
                  alt=""
                  :title="$t('public.Delete')"
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="currentPage4"
          :limit="pageSize"
          :total="total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
        <ul
          class="tableCheckBox"
          style=""
          v-if="isCheckShow"
          @mouseleave="leave"
        >
          <li @click="curSelection()">当前页全选</li>
          <!-- <li @click="toggleAllSelection()">所有页全选</li> -->
          <li @click="toggleSelection()">取消全选</li>
        </ul>
      </el-row>
    </el-row>
    <!-- 新增 -->
    <div v-if="flag == 2">
      <!-- <div> -->
      <el-row :gutter="16">
        <el-col :span="6">
          <el-form
            @submit.native.prevent
            class="demo-ruleForm"
            size="small"
            :model="form"
            ref="form"
            label-width="170px"
            style="margin-top: 10px; margin-top: 25px"
            :rules="rules"
          >
            <el-form-item
              :label="$t('ContentManage.Informmanage.Title') + '：'"
              prop="title"
            >
              <el-input v-model="form.title" size="small"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('ContentManage.Informmanage.Content') + '：'"
              prop="content"
            >
              <el-input
                v-model="form.content"
                type="textarea"
                :rows="6"
                show-word-limit
                id="text"
                size="small"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button
          type="primary"
          @click="add('form')"
          size="small"
          class="save"
          >{{ $t("public.Save") }}</el-button
        >
      </div>
    </div>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <Eldialog
      @handleClose="handleClose"
      :title="titleInfo"
      :dialogVisible="installFlag"
      :num="'470px'"
      :width="'900px'"
    >
      <infodetail
        :dictData="readVisibleData"
        ref="informref"
        v-if="installFlag"
      ></infodetail>
    </Eldialog>
    <dialog-info
      :dialogInfo="Successdialog"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import dialogInfo from "@/components/promptMessage/index";
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
import infodetail from "./components/Infodetail.vue";
export default {
  components: {
    pagination,
    naviGation,
    dialogInfo,
    Eldialog,
    infodetail,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      groupFullNameShowByIndex: "", //用户部门截取的下标
      //   分页
      currentPage4: 1, //当前页
      pageSize: 10, //每页显示条数
      total1: 0,
      total2: 0,
      tableAn1: [],
      tableAn2: [],
      pathImg: "",
      activeName: "first",
      installFlag: false,
      path: "",
      checked: true,
      isHidden: 1, // 显示当前 / 编辑页面
      typeInfo: "info",
      typeInfo1: "info",
      typeInfo2: "info",
      typeInfo3: "info",
      typeInfo4: "info",
      typeInfo5: "info",
      btnJiagu: true,
      rulesHTML: "dasdasd",
      num: 220,
      jiaGuVisible: false,
      flagbtn: false, //footer
      options1: [],
      options2: [],
      options3: [],
      // 提示框的提示语
      Successdialog: false, //控制弹出
      titleInfo: "",
      // applyFlag: false, //选择应用
      // statusVisible: false, //状态弹框
      title: "",
      content: "",
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 1, max: 20, message: "最长输入 20 个字符", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入内容", trigger: "blur" },
          { min: 1, max: 300, message: "最长输入 300 个字符", trigger: "blur" },
        ],
      },
      title1: "应用编辑",
      visibaelFlag: false, //提示消息默认--关闭
      dialogVisible: false, //详情弹框默认--关闭
      dataFrom: {}, //应用详情组件
      // table表格数据
      dictData: [],
      multipleSelection: [],
      queryObj: {
        pageNumber: 1,
        rowNumber: 10,
        manage: 1,
        appType: "",
        preventScreenshot: "",
        statu: "",
        name: "",
        sort: "",
      },
      total: 0,
      // 遮罩全选
      btnJiance: true,
      isCheckShow: false,
      hasSeleted: true,
      theShelves: true,
      shelves: true,
      deletesBtn: true,
      dataArray: {},
      jiaGlist: [],
      statusDown: false,
      // 新增
      flag: 1,
      form: {
        title: "", //名称
        content: "", //描述
        docCount: "", //级别，1-低，2-中，3-高
      },
      // 删除
      delarr: [],
      // 搜索
      search0: "",
      input: "", //弹框的查询
      // 排序
      page: {
        orderColume: "updateTime", //排序字段
        orderRule: "DESC", //排序规则ASC、DESC
      },
      readVisibleData: {
        tabID: 0,
      },
      tabID: "",
    };
  },
  computed: {
    hasSeleteds() {
      return this.delarr && this.delarr.length;
    },
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.tableList(); //应用商店table数据表格
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    handleSelectionChange(obj) {
      // console.log(obj, 'obj')
      this.multipleSelection = obj;
      this.delarr = obj;
      this.typeInfo = "primary";
      if (this.delarr.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 渲染数据列表
    async tableList() {
      var param = {
        currentPage: this.currentPage4,
        pageSize: this.pageSize,
        searchName: this.search0,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,

        // pageNumber: this.currentPage4, //页数
        // rowNumber: this.pageSize, //行数
        // sort: "n.createTime desc", //排序, desc-倒序， asc-正序, 支持字段：r.name(框架名称), r.updateTime(修改时间)
        // where: {
        //   //查询条件
        //   search0: this.search0,
        // },
      };
      // console.log(param);
      const res = await this.$axios.post(
        "/httpServe/notice/getDataInfo",
        param,
        true
      );
      // console.log(res, '通知管理列表')
      this.dictData = res.data.content;
      this.total = res.data.total;
    },
    // 新增
    isflag() {
      this.flag = 2;
    },
    add(form) {
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          // 请求接口
          var param = {
            // id: this.id,
            title: this.form.title, //名称
            content: this.form.content, //描述
          };
          // console.log(param)
          const res = await this.$axios.post(
            "/httpServe/notice/save",
            param,
            true
          );
          // console.log(res)
          if (res.data == "0") {
            this.$message({
              message: this.$t("ContentManage.Informmanage.SaveError"),
              type: "error",
              offset: 100,
            });
            return false;
          } else {
            // this.Successdialog = true
            // this.titleInfo = '保存成功'
            this.$message({
              message: this.$t("public.SuccessfullySaved"),
              type: "success",
              offset: 100,
            });
            this.flag = 1;
          }
          this.tableList();
          (this.form = {
            name: "", //名称
            memo: "", //描述
          }),
            (this.flag = 1);
        } else {
          // console.log("error");
          return false;
        }
      });
    },
    delArr(obj) {
      var ids = [];
      obj.forEach((item) => {
        ids.push(item.id);
      }); // 请求接口
      // console.log(ids);
      var parma = { ids: ids, deleteAll: 2 };

      this.$confirm(
        this.$t("ContentManage.Informmanage.SaveseleError"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/notice/delete",
            parma,
            true
          );
          // this.Successdialog = true
          // this.titleInfo = '删除成功'
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTable.clearSelection();
          this.currentPage4 = 1;
          this.tableList();
        })
        .catch(() => {
          return false;
        });
    },
    del(id) {
      // 请求接口
      var parma = { ids: [id], deleteAll: 2 };
      this.$confirm(
        this.$t("ContentManage.Informmanage.SaveseleError"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          customClass: "persdsd",
          type: "warning",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/notice/delete",
            parma,
            true
          );
          // console.log(res)
          // this.Successdialog = true
          // this.titleInfo = '删除成功'
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.currentPage4 = 1;
          this.tableList();
        })
        .catch(() => {
          return false;
        });
    },
    checkChange(selection, row) {
      selection.forEach((item) => {
        this.delarr.push(item.id);
      });
    },
    testShop() {
      // console.log(this.dataArray);
      if (this.dataArray.osTypeId == 20) {
        this.isHidden = 4;
      } else {
        this.isHidden = 6;
      }
    },
    selectEnable(row, rowIndex) {
      if (row.purgeStatus != 2 && row.scanScore != -1) {
        return true;
      } else {
        return false;
      }
    },
    nameDetail(row) {
      // this.tabID = row.id
      this.readVisibleData.tabID = row.id;
      // console.log(this.readVisibleData.tabID, "ididididididiid")
      // var params = {
      //   ids: [this.tabID]
      // }
      // // console.log(params)
      // const res = await this.$axios.post(
      //   '/httpServe/notice/installDevice',
      //   params,
      //   true
      // )
      // console.log(res);
      // this.readVisibleData = res.data
      this.titleInfo = this.$t("ContentManage.Informmanage.TitleInfo");
      this.installFlag = true;
    },
    // 分配
    jump2Detail: function (menu, param) {
      this.$axios.setOneTimeParam("menu", menu);
      this.$parent.$parent.$parent.$parent.$parent.clickMenu();
      if (param) {
        this.$axios.postMessage(param);
      }
    }, // 分配
    distribution(row) {
      let newData = [];
      newData.push({
        value: 5,
        idList: [row.id],
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    distribution1() {
      let data = [];

      // console.log(this.multipleSelection, 'this.multipleSelection')
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id);
      });

      // console.log(data, '分配通知')
      let newData = [];
      newData.push({
        value: 5,
        idList: data,
      });

      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    sort(column) {
      // console.log(column.order, column.prop);
      if (column.prop == "title") {
        if (column.order === "ascending") {
          this.page.orderRule = "ASC";
        } else if (column.order === "descending") {
          this.page.orderRule = "DESC";
        }
        this.page.orderColume = column.prop;
      }
      if (column.prop == "content") {
        if (column.order === "ascending") {
          this.page.orderRule = "ASC";
        } else {
          this.page.orderRule = "DESC";
        }
        this.page.orderColume = column.prop;
      }
      if (column.prop == "groupFullName") {
        if (column.order === "ascending") {
          this.page.orderRule = "ASC";
        } else {
          this.page.orderRule = "DESC";
        }
        this.page.orderColume = column.prop;
      }
      if (column.prop == "userName") {
        if (column.order === "ascending") {
          this.page.orderRule = "ASC";
        } else {
          this.page.orderRule = "DESC";
        }
        this.page.orderColume = column.prop;
      }
      if (column.prop == "updateTime") {
        if (column.order === "ascending") {
          this.page.orderRule = "ASC";
        } else {
          this.page.orderRule = "DESC";
        }
        this.page.orderColume = column.prop;
      }
      this.tableList();
    },

    // 查询
    searchList() {
      if (this.search0.length > 0) {
        this.currentPage4 = 1;
      }
      this.queryObj.pageNumber = 1;
      this.tableList();
    },

    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage4 = page;
      this.pageSize = limit;
      // 调用查询方法
      this.tableList();
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    // 关闭
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
      this.dialogVisible = false; //详情弹框  关闭
      this.jiaGuVisible = false; //加固详情
      this.installFlag = false;
      this.Successdialog = false; //提示消息  关闭
      this.input = ""; //弹框输入框清空
      // this.$informref._data.input = ''
    },
    heightCustom() {
      this.height.height = this.num;
    },
    //确定回调事件
    determine() {
      this.Successdialog = false; //提示消息  关闭
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.common-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
  .elRow {
    margin-bottom: 10px;
  }
}
.tabs_box {
  margin-top: 10px;
  height: 96%;
  border: 1px solid #ccc;
}

.el-tabs__content {
  padding: 5px 10px;
}

.save {
  margin: 4px 0 0 170px;
}

:v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: none;
}

.el-linkStyle {
  display: flex;
  cursor: pointer;
  color: #1e89e5;

  img {
    width: 20px;
    line-height: 20px;
    // margin-top: 20px;
  }
}

::v-deep input::-webkit-input-placeholder {
  // color: red;
  font-size: 12px;
}

.processManage .callenia {
  height: 0;
}

::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

::v-deep .el-checkbox__label {
  color: #000;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}

::v-deep .el-table__empty-text {
  line-height: 0px;
  width: 60px;
  color: #909399;
}

::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
