<template>
  <div class="tab">
    <el-input v-model="input" size="small" style="
        width: 200px;
        margin-top: 2px;
        position: absolute;
        margin-left: 600px;
        z-index: 9999;
      " :placeholder="$t('ContentManage.Informmanage.PlaceDeviceName')" @keyup.enter.native="searchDev">
      <i style="cursor: pointer" class="el-icon-search el-input__icon" slot="suffix" @click="searchDev">
      </i>
    </el-input>
    <el-tabs v-model="activeName" type="card" style="position: relative" @tab-click="handleLoad">
      <el-tab-pane :label="$t('public.HaveRead')" name="1">
        <el-table size="mini" stripe class="sort_table" :data="readDevice" style="width: 820px; margin: 5px 10px 0 10px; overflow-y: auto" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }">
          <el-table-column :label="$t('public.DeviceName')" width="205" prop="deviceName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.deviceName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Username')" width="205" prop="loginName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Name')" prop="loginName" width="205" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Section')" prop="groupFullName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- <span style="margin-left: 10px">共有{{ total }}条记录</span> -->
        <div style="margin-left: 10px">
          <pagination :page="searchData.currentPage" :limit="searchData.pageSize" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('public.Assigned')" name="2">
        <el-table :data="visibleDevice" size="mini" stripe class="sort_table" style="width: 820px; margin: 5px 10px 0 10px; overflow-y: auto" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{ prop: 'deviceName', order: 'descending' }">
          <el-table-column prop="deviceName" :label="$t('public.DeviceName')" width="205" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.deviceName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Username')" width="205" prop="loginName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.loginName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Name')" prop="userName" width="205" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.userName }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Section')" prop="groupFullName" :show-overflow-tooltip="true">
            <template v-slot="{ row }">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- <span style="margin-left: 10px">共有{{ dictData.visibleDevice.length }}条记录</span> -->
        <div style="margin-left: 10px">
          <pagination :page="searchData.currentPage" :limit="searchData.pageSize" :total="total1" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import pagination from '@/components/pagination/page'
export default {
  components: {
    pagination
  },
  props: {
    dictData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      groupFullNameShowByIndex: '', //用户部门截取的下标
      input: '',
      activeName: '1',
      readDevice: [],
      visibleDevice: [],
      searchData: {
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      total1: 0
    }
  },
  watch: {
    'dictData.tabID': {
      handler(newVal, oldVal) {
        // console.log(oldVal, "旧值1");
        // console.log(newVal, "新值2");
        this.dictData.tabID = newVal
        setTimeout(() => {
          this.nameDetail()
          this.getVisible()
          this.activeName = '1'
        }, 200)
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (this.dictData) {
      this.activeName = '1'
    }
    // this.nameDetail();
    // this.getVisible();
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    // this.nameDetail();
    // this.getVisible();
  },
  methods: {
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.searchData.currentPage = page
      this.searchData.pageSize = limit
      // 调用查询方法
      this.nameDetail()
      this.getVisible()
    },
    // 阅读
    async nameDetail() {
      // console.log(this.dictData.tabID, "this.dictData.tabID1111");
      var parma = {
        id: this.dictData.tabID,
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        deviceName: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/notice/readDevice',
        parma,
        true
      )
      // console.log(res);
      this.readDevice = res.data.content
      this.total = res.data.total
    },
    // 分配
    async getVisible() {
      var parma = {
        id: this.dictData.tabID,
        currentPage: this.searchData.currentPage,
        pageSize: this.searchData.pageSize,
        deviceName: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/notice/deliveryDevice',
        parma,
        true
      )
      // console.log(res);
      this.visibleDevice = res.data.content
      this.total1 = res.data.total
    },
    // 弹框查询
    searchDev() {
      if (this.activeName == '1') {
        this.nameDetail()
      } else {
        this.getVisible()
      }
      // if (this.input != '') {
      //   this.dictData.readDevice = this.dictData.readDevice.filter((item) => {
      //     return item.deviceName
      //       .toLowerCase()
      //       .includes(this.input.toLowerCase())
      //   })
      //   this.dictData.visibleDevice = this.dictData.visibleDevice.filter(
      //     (item) => {
      //       return item.deviceName
      //         .toLowerCase()
      //         .includes(this.input.toLowerCase())
      //     }
      //   )
      // } else {
      //   this.nameDetail()
      // }
    },
    // tab切换
    handleLoad(data) {
      let name = data.name
      if (name == '1') {
        this.input = ''
        this.nameDetail()
      } else if (name == '2') {
        this.input = ''
        this.getVisible()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.tab {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 450px;
  position: relative;
  //   overflow: auto;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-table__empty-text {
  line-height: 60px;
  width: 55%;
  color: #909399;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
